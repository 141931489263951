// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.column.right {
  justify-content: start;
  align-self: center;
}

video {
  max-width: 100%;
  width: 100%;
  height: auto;
  max-height: 45rem;
  margin-top: 1.25rem;
}

@media (max-width: 50rem) {
  .container {
    grid-template-columns: 1fr;
  }

  .column {
    padding: 0.625rem; /* 10px */
  }

  .column.left,
  .column.right {
    justify-content: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/video/video.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE;IACE,0BAA0B;EAC5B;;EAEA;IACE,iBAAiB,EAAE,SAAS;EAC9B;;EAEA;;IAEE,uBAAuB;EACzB;AACF","sourcesContent":[".column.right {\n  justify-content: start;\n  align-self: center;\n}\n\nvideo {\n  max-width: 100%;\n  width: 100%;\n  height: auto;\n  max-height: 45rem;\n  margin-top: 1.25rem;\n}\n\n@media (max-width: 50rem) {\n  .container {\n    grid-template-columns: 1fr;\n  }\n\n  .column {\n    padding: 0.625rem; /* 10px */\n  }\n\n  .column.left,\n  .column.right {\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
