// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #4caf50;
  --focus-color: #0f5511;
  --border-fields-color: #ccc;
}

.App {
  text-align: center;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1.25rem;
}

@media (max-width: 50rem) {
  .container {
    grid-template-columns: 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[":root {\n  --primary-color: #4caf50;\n  --focus-color: #0f5511;\n  --border-fields-color: #ccc;\n}\n\n.App {\n  text-align: center;\n}\n\n.container {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  padding: 1.25rem;\n}\n\n@media (max-width: 50rem) {\n  .container {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
