import "./form.css";
import React, { useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "../toast/showToast";

function Form() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    console.log("Email:", email);
    try {
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      const res = await axios.post("https://dogtoheel.online/subscribe", {
        email,
      });
      showToast(JSON.stringify(res.data), "success");
    } catch (error) {
      showToast("Mail could not be sent. Try again, please.", "error");
      console.error("Error al realizar la llamada POST:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
      <div className="column left">
        <form onSubmit={handleSubmit}>
          <h2>Focused heel, how it's done without leash and collars.</h2>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            placeholder={"Enter your email"}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button
            type="submit"
            className="loading-button"
            disabled={loading}
          >
            {loading ? "Discovering" : "Discover it"}
          </button>
        </form>
      </div>
  );
}

export default Form;
