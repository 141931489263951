import "./App.css";
import { ToastContainer } from "react-toastify";
import Video from "./video/video";
import Form from "./form/form";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <div className="container">
        <Form/>
        <Video/>
      </div>
    </div>
  );
}

export default App;
