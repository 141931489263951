import "./video.css";
import React, { useEffect } from "react";
import Bowser from "bowser";

function Video() {
  const getUserDeviceInfo = () => {
    return Bowser.getParser(window.navigator.userAgent).getResult();
  };

  useEffect(() => {
    const visitInfo = getUserDeviceInfo()
    // ReactGA.event({
    //   category: 'visit',
    //   os: visitInfo.os,
    //   engine : visitInfo.engine,
    //   platform : visitInfo.platform,
    //   browser : visitInfo.browser,
    // });
    const video = document.getElementById("video");
    video.play();
  }, []); // This will run only once after the component mounts

  return (
        <div className="column right">
          <div>
            <video
              id="video"
              autoPlay
              loop
              controls
              muted
            >
              <source
                src="/heel.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
  );
}

export default Video;
